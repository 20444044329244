import SiteWrapper from "../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../assets/images/pagerduty_dxable_og.png";
import { useEffect } from "react";

import MdRenderer from "../components/mdrender";
import * as md from "../components/mdrender.module.sass";
import TitleBlock from "../components/title";

import "./readme@gen.scss";

const GeneratedReadme = () => {
  useEffect(() => {
    var sc, d;
    try {
      sc = document.createElement("script");
      sc.src =
        "https://digitalstackscorporation-team.myfreshworks.com/crm/sales/web_forms/51fa1858011238887af63ab58f91182c7504e6be018ce658274dabd526776b47/form.js";
      sc.crossOrigin = "anonymous";
      sc.id =
        "fs_51fa1858011238887af63ab58f91182c7504e6be018ce658274dabd526776b47";
      d = document.getElementById("contact-form");
      d.appendChild(sc);

      new MutationObserver(function (mutationsList, observer) {
        mutationsList.forEach((mutation) => {
          if (mutation.addedNodes) {
            mutation.addedNodes.forEach((node) => {
              var css = node.querySelector
                ? node.querySelector('link[href*="webforms.css"]')
                : null;
              if (css) {
                observer.disconnect();
                css.addEventListener("load", function () {
                  setTimeout(function () {
                    loop: for (
                      var i = 0;
                      i < document.styleSheets.length;
                      i++
                    ) {
                      var styleSheet = document.styleSheets[i];
                      if (
                        styleSheet.href &&
                        styleSheet.href.includes("webforms.css")
                      ) {
                        var rules = styleSheet.rules;
                        for (var j = 0; j < rules.length; j++) {
                          var rule = rules[j];
                          if (
                            rule.selectorText &&
                            rule.selectorText.includes(".hidden,")
                          ) {
                            var cssText = rule.cssText.replace(
                              ".hidden,",
                              ".fs-webform-container .hidden,"
                            );
                            styleSheet.deleteRule(j);
                            styleSheet.insertRule(cssText, j);
                            break loop;
                          }
                        }
                      }
                    }
                  });
                });
              }
            });
          }
        });
      }).observe(document.body, { childList: true, subtree: true });
    } catch (e) {}

    return () => {
      if (sc && d) d.removeChild(sc);
    };
  }, []);

  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          Japan IT Week PagerDuty スタートガイドブック期間限定 無料プレゼント! -
          PagerDuty正規代理店 DXable
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="監視ツールやアプリからのアラートを確実に担当者に通報する SaaSインシデント対応
プラットフォーム製品"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="監視ツールやアプリからのアラートを確実に担当者に通報する SaaSインシデント対応
プラットフォーム製品"
        />

        <meta
          property="og:title"
          content="Japan IT Week PagerDuty スタートガイドブック期間限定 無料プレゼント! - PagerDuty正規代理店 DXable"
        />

        <meta
          property="og:description"
          content="監視ツールやアプリからのアラートを確実に担当者に通報する SaaSインシデント対応
プラットフォーム製品"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/readme/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/readme/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow=""
        textGreen="PagerDuty スタートガイドブック 期間限定 無料プレゼント!"
        overview=""
      />

      <div className="readmeWrapper">
        <div className="readme-container">
          <div className="center-2 mt-[5px] md:mt-[0px]">
            <h2 className="center-title">
              PagerDuty スタートガイドブック <br />
              期間限定 無料プレゼント!
            </h2>
            <p className="center-para">
              監視ツールやアプリからのアラートを確実に担当者に通報する
              SaaSインシデント対応 <br />
              プラットフォーム製品
            </p>
          </div>
          <div className="two-col-media-contents mb-[100px] items-start gap-x-[80px]">
            <div className="media-contents-part">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/dfbb78c9215ac7f6351345f3a6022caf_b95fa510b8.png"
                alt="PagerDuty スタートガイドブック 期間限定 無料プレゼント!"
              />
            </div>
            <div className="text-part">
              <div className="w-full -mt-[1.6rem]">
                <div id="contact-form" />
                <p>
                  <a
                    target="_blank"
                    href="https://corp.digitalstacks.net/privacy-policy"
                    className="text-[#4EB346]"
                  >
                    個人情報保護方針
                  </a>
                  についてご確認の上、ご同意いただける場合は上記の項目にチェックを入れてご送信ください。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedReadme;
